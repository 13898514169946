import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, DragIndicator, CheckBox } from 'components/ui';
import { useEffect, useRef, useState } from 'react';
import { useSortTagMutation } from 'api/internal.api.ts';
import findIndex from 'lodash/findIndex';
import { MuiColorInput } from 'mui-color-input';
import Color from 'color';

import { base62 } from 'mudder';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useUpdateHealthDamageMutation } from 'api/internal.api.ts';
import { calcColors } from 'components/schlagworte/components/LexiconGridView.js';
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
	@media print {
		color: black !important;
	}
`;

const LexiconDropzone = (props) => {
	const globHealthDamages = useSelector(
		(state) => state.masterData.globHealthDamages
	);
	const [sortTag] = useSortTagMutation();
	const { level, tag, position, data } = props;
	const [active, setActive] = useState(false);

	const onDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
		e.dataTransfer.dropEffect = 'move';
		setActive(true);
	};

	const checkSelfParent = (dragId, targetId) => {
		let parentId = targetId;
		for (let i = 0; i < 10; i++) {
			if (
				parentId == null ||
				parentId == 'null' ||
				parentId == '0' ||
				parentId == 0
			) {
				return true;
			}
			if (dragId == parentId) {
				return false;
			}
			const parent = globHealthDamages?.find((item) => {
				return item.glob_health_damage_id == parentId;
			});
			parentId = parent?.parent_health_damage_id;
			if (parentId === undefined) {
				return false;
			}
		}
	};

	const dropHandler = async (e) => {
		props.setDragging(false);
		setActive(false);
		e.preventDefault();
		const dragItem = JSON.parse(e.dataTransfer.getData('text/plain'));
		let newLexicalOrder = 'c';

		if (
			!checkSelfParent(
				dragItem.glob_health_damage_id,
				tag.glob_health_damage_id
			)
		) {
			toast('Schlagwort darf nicht unter sich selber einsortiert werden.');
			return;
		}
		switch (level) {
			case 1:
				if (data[0].length == 1) {
					newLexicalOrder = base62.mudder('1', tag.lexical_order)[0];
				} else if (position == 'before') {
					const index = findIndex(data[0], (item) => {
						return item.glob_health_damage_id == tag.glob_health_damage_id;
					});
					if (index == 0) {
						newLexicalOrder = base62.mudder('1', tag.lexical_order)[0];
					} else {
						newLexicalOrder = base62.mudder(
							data[0][index - 1].lexical_order,
							tag.lexical_order
						)[0];
					}
				} else if (position == 'after') {
					const index = findIndex(data[0], (item) => {
						return item.glob_health_damage_id == tag.glob_health_damage_id;
					});

					newLexicalOrder = base62.mudder(
						data[0][index + 1].lexical_order,
						tag.lexical_order
					)[0];
				}
				break;
			default:
				if (position == 'inside') {
					newLexicalOrder = base62.mudder('1', 'z')[0];
				} else if (position == 'before') {
					const location =
						tag.parent_health_damage_id === null
							? 'others'
							: tag.parent_health_damage_id;
					const index = findIndex(data[location], (item) => {
						return item.glob_health_damage_id == tag.glob_health_damage_id;
					});
					if (index == 0) {
						newLexicalOrder = base62.mudder('1', tag.lexical_order)[0];
					} else {
						newLexicalOrder = base62.mudder(
							data[location][index - 1].lexical_order,
							tag.lexical_order
						)[0];
					}
				} else if (position == 'after') {
					const location =
						tag.parent_health_damage_id === null
							? 'others'
							: tag.parent_health_damage_id;
					const index = findIndex(data[location], (item) => {
						return item.glob_health_damage_id == tag.glob_health_damage_id;
					});
					if (index == data[location].length - 1) {
						newLexicalOrder = base62.mudder(tag.lexical_order, 'z')[0];
					} else {
						newLexicalOrder = base62.mudder(
							data[location][index + 1].lexical_order,
							tag.lexical_order
						)[0];
					}
				}
				break;
		}

		props.setDraggedItem({});

		const result = await sortTag({
			glob_health_damage_id: dragItem.glob_health_damage_id,
			lexical_order: newLexicalOrder,
			parent_health_damage_id: props.inside
				? tag.glob_health_damage_id
				: tag.parent_health_damage_id,
		})
			.unwrap()
			.catch((e) => {
				console.log(e);
			});
		if (result?.result == 'success') {
			props.getHealthDamages();
		} else {
			console.log('fehler beim speichern', result);
		}
	};

	let activeTimeout;
	return (
		<div
			className={props.lastItem ? 'w-50' : 'w-100'}
			onDrop={dropHandler}
			onDragOver={onDragOver}
			onDragLeave={(e) => {
				e.preventDefault();
				clearTimeout(activeTimeout);
				activeTimeout = setTimeout(() => {
					setActive(false);
				}, 100);
			}}
			style={Object.assign(
				{
					height: props.edit ? '24px' : 'auto',
					overflow: 'hidden',
					backgroundColor: active
						? 'transparent'
						: props.levelColorMap[props.level],
					opacity: active ? '1' : '.0',
					position: props.lastItem ? 'absolute' : 'relative',
				},
				props.position == 'before'
					? { top: '0px', left: '0px' }
					: props.position == 'after'
					? { bottom: '0px', left: '0px' }
					: { bottom: '0px', right: '0px' }
			)}
		>
			<LexiconItem
				{...props}
				levelBgColorMap={
					props.tag.color ? calcColors(props.tag.color) : props.levelBgColorMap
				}
				levelColorMap={
					props.tag.color ? calcColors(props.tag.color) : props.levelColorMap
				}
				style={{ pointerEvents: 'none' }}
				preview={true}
				key={'active-item'}
				tag={props.draggedItem} //{active ? props.draggedItem : {}}
			/>
		</div>
	);
};

LexiconDropzone.propTypes = {
	tag: PropTypes.object,
	draggedItem: PropTypes.object,
	data: PropTypes.object,
	setDraggedItem: PropTypes.func,
	level: PropTypes.number,
	levelColorMap: PropTypes.array,
	levelBgColorMap: PropTypes.array,
	lastItem: PropTypes.bool,
	dragging: PropTypes.bool,
	edit: PropTypes.bool,
	editPlus: PropTypes.bool,
	inside: PropTypes.bool,
	setDragging: PropTypes.func,
	getHealthDamages: PropTypes.func,
	position: PropTypes.string,
	showId: PropTypes.bool,
};

export const calcTextColor = (color) => {
	// let bgColor = props.lastItem ? calcBgColor(color) : color;
	// let newColor = Color(bgColor).luminosity() < 0.5 ? 'white' : '#5E5E5E';
	// return newColor;
	if (!color) {
		return '#5E5E5E';
	}
	return Color(color).luminosity() < 0.5 ? 'white' : '#5E5E5E';
};

const LexiconItem = (props) => {
	const [t] = useTranslation();
	const dragRef = useRef();
	const [updateHealthDamage, { isLoading, isFetching }] =
		useUpdateHealthDamageMutation();
	const [selected, setSelected] = useState(
		props.selected?.indexOf(props.tag.glob_health_damage_id) > -1 ||
			props.selected?.indexOf(props.tag.glob_health_damage_id.toString()) > -1
	);

	let startDragReference;

	const updateTag = async (change) => {
		const result = await updateHealthDamage({
			change,
			id: props.tag.glob_health_damage_id,
		})
			.unwrap()
			.catch((e) => {
				console.log(e);
			});
		if (result?.result == 'success') {
			props.getHealthDamages();
		}
	};

	const dragStartHandler = (e) => {
		if (!props.edit) {
			return;
		}
		clearTimeout(startDragReference);
		startDragReference = setTimeout(() => props.setDragging(true), 100);
		e.dataTransfer.setData(
			'text/plain',
			JSON.stringify({ ...props.tag, ...{ originLevel: props.level } })
		);
		props.setDraggedItem(props.tag);
	};

	const calcBgColor = (color) => {
		let obj = Color(color).object();
		obj.r = 255 - 0.33 * (255 - obj.r);
		obj.g = 255 - 0.33 * (255 - obj.g);
		obj.b = 255 - 0.33 * (255 - obj.b);
		return Color(obj).hex();
	};

	const textColor = calcTextColor(props.levelColorMap[props.level]);

	const lastItemSyle = {
		paddingLeft: 15 + (props.level - 1) * 20 + 'px',
		// borderLeft: '5px solid ' + props.levelColorMap[1],
		paddingRight: '40px',
		// backgroundColor: Color(props.levelBgColorMap[1]).lighten(
		// 	Color(props.levelBgColorMap[1]).luminosity() / 0.75
		// ),
		// backgroundColor: calcBgColor(props.levelBgColorMap[1]),
		backgroundColor: props.levelBgColorMap[props.level],
	};

	const previewStyle = {
		paddingLeft: '10px', //30 - props.level * 5 + 'px',
		marginLeft: '-15px',
		// borderLeft: '5px solid ' + props.levelColorMap[props.level],
		paddingRight: '40px',
		// height: '29px',
		// overflow: 'hidden',
	};

	const draggedStyle = {
		// borderLeft: '5px solid ' + props.levelColorMap[props.level],
		// paddingLeft: '15px', //30 - props.level * 5 + 'px',
		maxWidth: '200px',
	};

	const commonStyle = {
		paddingLeft: (props.level - 1) * 20 + 'px',
	};

	const isBeingDragged =
		props.tag.glob_health_damage_id === props.draggedItem.glob_health_damage_id;

	useEffect(() => {
		setSelected(
			props.selected?.indexOf(props.tag.glob_health_damage_id) > -1 ||
				props.selected?.indexOf(props.tag.glob_health_damage_id.toString()) > -1
		);
	}, [props.selected]);

	return (
		<div
			className='d-flex w-100 flex-column position-relative'
			style={{
				backgroundColor: props.levelColorMap[props.level],
				pointerEvents: props.preview ? 'none' : 'unset',
				// position: 'relative',
				opacity:
					!props.preview &&
					props.draggedItem.glob_health_damage_id ==
						props.tag.glob_health_damage_id
						? '.0'
						: '1',
				// visibility:
				// 	!props.preview &&
				// 	props.draggedItem.glob_health_damage_id ==
				// 		props.tag.glob_health_damage_id
				// 		? 'hidden'
				// 		: 'visible',
			}}
		>
			{props.dragging &&
				!props.preview &&
				props.draggedItem.glob_health_damage_id !==
					props.tag.glob_health_damage_id && (
					<LexiconDropzone
						{...props}
						position='before'
						key={props.tag.glob_health_damage_id + 'before'}
						style={{
							position: props.lastItem ? 'absolute' : 'relative',
							top: props.lastItem ? '0' : 'unset',
						}}
					/>
				)}
			<div
				draggable={
					props.preview || !props.edit || props.tag.lexical_order == 'z'
						? false
						: true
				}
				onDragStart={dragStartHandler}
				onDragEnd={() => {
					if (!props.edit) {
						return;
					}
					props.setDragging(false);
					props.setDraggedItem({});
					clearTimeout(startDragReference);
				}}
				className='d-flex w-100 align-items-center'
				style={
					props.lastItem
						? lastItemSyle
						: props.preview
						? previewStyle
						: isBeingDragged
						? draggedStyle
						: commonStyle
				}
			>
				{props.edit && (
					<div
						className='cursor-grab d-flex'
						ref={dragRef}
						style={{ color: textColor }}
					>
						<DragIndicator />
					</div>
				)}
				{props.selectionMode && (
					<div
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					>
						<CheckBox
							label=''
							onChange={() => {
								props.onSelect(props.tag);
							}}
							checked={!!selected}
						/>
					</div>
				)}
				<StyledTypography
					sx={{}}
					style={{
						// color: Color(calcTextColor(props.levelColorMap[1])),
						color: textColor,
						padding:
							(props.dragging && !props.lastItem) || props.preview
								? '0px'
								: props.edit
								? '24px 0px'
								: '10px 0',
					}}
				>
					{props.tag.name
						? props.tag.name
						: props.tag.health_damage_i18n_key
						? t('master_data.health_damage.' + props.tag.health_damage_i18n_key)
						: ' '}
				</StyledTypography>
			</div>
			{props.editPlus && !props.dragging && (
				<div
					className='d-flex position-absolute bottom-0 right-0 align-items-end'
					style={{
						right: 0,
						paddingRight: props.lastItem ? '40px' : 0,
						color: textColor,
					}}
					onClick={(e) => {
						// e.stopPropagation();
						// e.preventDefault();
					}}
				>
					<div
						className='cursor-pointer'
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
					>
						<CheckBox
							label={t('common.ist_verifiziert')}
							onChange={(e) => {
								e.preventDefault();
								e.stopPropagation();
								updateTag({ verified: !props.tag.verified });
							}}
							checked={!!props.tag.verified}
						/>
					</div>
					<div
						className='cursor-pointer'
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
					>
						<CheckBox
							label={t('common.ist_auswaehlbar')}
							onChange={(e) => {
								e.preventDefault();
								e.stopPropagation();
								updateTag({ is_selectable: !props.tag.is_selectable });
							}}
							checked={!!props.tag.is_selectable}
						/>
					</div>
					<div
						className='cursor-pointer'
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
						// onClick={(e) => {
						// 	e.stopPropagation();
						// 	updateTag({ is_pre_illness: !props.tag.is_pre_illness });
						// }}
					>
						<CheckBox
							label={t('common.vorerkrankung')}
							onChange={(e) => {
								e.preventDefault();
								e.stopPropagation();
								updateTag({ is_pre_illness: !props.tag.is_pre_illness });
							}}
							checked={!!props.tag.is_pre_illness}
						/>
					</div>
					<div
						className='cursor-pointer'
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
						// onClick={(e) => {
						// 	e.stopPropagation();
						// 	updateHealthDamage({ is_vax_damage: !props.tag.is_vax_damage });
						// }}
					>
						<CheckBox
							label={t('common.impfschaden')}
							onChange={(e) => {
								e.preventDefault();
								e.stopPropagation();
								updateTag({ is_vax_damage: !props.tag.is_vax_damage });
							}}
							checked={!!props.tag.is_vax_damage}
						/>
					</div>
					<div className='d-flex flex-column align-items-end '>
						{props.showId && (
							<div className='me-2'>ID: {props.tag.glob_health_damage_id}</div>
						)}
						<div
							style={{ color: textColor }}
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}
						>
							<MuiColorInput
								size='small'
								format='hex'
								sx={{
									width: '130px',
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: textColor,
									},
									'& .MuiInputBase-input': {
										color: textColor,
									},
								}}
								value={props.tag.color || ''}
								onChange={(newValue) => {
									if (newValue == '#ffffff') {
										newValue = 'empty';
									}
									updateTag({ color: newValue });
								}}
							/>
						</div>
					</div>
				</div>
			)}
			<div className='d-flex w-100 position-relative'>
				{props.dragging &&
					props.tag.lexical_order != 'z' &&
					props.draggedItem.glob_health_damage_id !==
						props.tag.glob_health_damage_id &&
					!props.preview && (
						<LexiconDropzone
							{...props}
							position='after'
							key={props.tag.glob_health_damage_id + 'after'}
						/>
					)}
				{props.lastItem &&
					props.dragging &&
					props.draggedItem.glob_health_damage_id !==
						props.tag.glob_health_damage_id &&
					!props.preview && (
						<LexiconDropzone
							{...props}
							position='inside'
							level={props.level + 1}
							inside={true}
							key={props.tag.glob_health_damage_id + 'inside'}
						/>
					)}
			</div>
		</div>
	);
};

LexiconItem.propTypes = {
	tag: PropTypes.object,
	level: PropTypes.number,
	levelColorMap: PropTypes.array,
	levelBgColorMap: PropTypes.array,
	lastItem: PropTypes.bool,
	preview: PropTypes.bool,
	dragging: PropTypes.bool,
	edit: PropTypes.bool,
	editPlus: PropTypes.bool,
	selectionMode: PropTypes.bool,
	setDragging: PropTypes.func,
	draggedItem: PropTypes.object,
	setDraggedItem: PropTypes.func,
	data: PropTypes.object,
	getHealthDamages: PropTypes.func,
	selected: PropTypes.array,
	onSelect: PropTypes.func,
};

export { LexiconItem };
