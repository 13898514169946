import { Modal, Input, AngleDown, Switch } from 'components/ui';
import { useSelector, useDispatch } from 'react-redux';
import useFormUpdate from 'components/impfschaeden/assistent/hooks/useFormUpdate';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import i18n from '../../../../i18n';
// Vorlaeufig deaktiviert
// import { useLanguagesQuery } from 'api/kosmos.api';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/ja';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';
import 'dayjs/locale/it';
import LocalizedFormat from 'dayjs/plugin/localizedFormat.js';
import LocaleData from 'dayjs/plugin/localeData.js';
import { settingsActions } from 'store/settingsSlice.ts';
import styled from 'styled-components';
import { InfoOutlined } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { availableLanguages } from 'lib/utils.js';
import { useRouter } from 'next/router.js';
import { useRouteTranlationsLazyQuery } from 'api/glob.api.js';

const ButtonSprache = styled.div`
	position: relative;
	margin-right: 10px;
	border: 3px var(--bs-body-color) solid;
	width: 70px;
	height: 35px;
	padding: 10px;
	display: flex;
	margin-top: 2px;
	border-radius: 5px;
	cursor: pointer;
	&:last-child {
		background-color: white;
	}
	> div {
		position: absolute;
		left: 5px;
		top: 1px;
		color: var(--bs-body-color);
		font-weight: bolder;
	}
	> div:first-child {
		font-size: 20px;
	}
`;

dayjs.extend(LocalizedFormat);
dayjs.extend(LocaleData);
dayjs.locale(i18n.language?.substring(0, 2) || 'en');

export default function Switcher() {
	const [fetchRouteTranslations] = useRouteTranlationsLazyQuery();
	const [routeTranslations, setRouteTranslations] = useState([]);
	const { language: langIsoCode, machineTranslation } = useSelector(
		(state) => state.settings
	);
	const [language, setLanguage] = useState(
		window?.location?.pathname?.substring(1, 3) || 'en'
	);
	const { update } = useFormUpdate();
	const [show, setShow] = useState(false);
	const [search, setSearch] = useState('');
	const [showInfo, setShowInfo] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [t] = useTranslation();
	// Vorlaeufig deaktiviert
	// const { data } = useLanguagesQuery();

	const data = availableLanguages;

	const [filteredData, setFilteredData] = useState([]);
	const dispatch = useDispatch();
	const router = useRouter();

	const getRouteTranslations = async () => {
		const result = await fetchRouteTranslations().unwrap();
		if (result.routeTranslations) {
			setRouteTranslations(result.routeTranslations);
		}
	};

	function translatePathname(pathname, language) {
		return pathname
			.split('/')
			.map((segment) => {
				const translation = routeTranslations.find((entry) => {
					return entry.i18n_key == `routing.${segment}`;
				});
				if (translation) {
					return translation[language];
				} else {
					return segment;
				}
			})
			.join('/');
	}

	function changeLanguageOnClick(language) {
		// console.log('changeLanguageOnClick', language, routeTranslations);
		i18n.changeLanguage(language);
		dayjs.locale(language);
		dispatch(settingsActions.setLanguage(language));
		document.cookie = `NEXT_LOCALE=${language}; path=/; max-age=31536000`;
		const { pathname, asPath, query } = router;
		let translatedPath = translatePathname(pathname, language);
		translatedPath = translatedPath.replace('[lang]', language);
		translatedPath = translatedPath.replace('[id]', query.id);
		// console.log(
		// 	'switch language',
		// 	language,
		// 	pathname,
		// 	query,
		// 	asPath,
		// 	router,
		// 	routeTranslations,
		// 	translatedPath
		// );
		router.push({ pathname, query }, translatedPath, { locale: language });
	}

	useEffect(() => {
		setFilteredData(
			data.filter((country) => {
				return (
					country.lang_name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
					country.lang_iso_code.toLowerCase().indexOf(search.toLowerCase()) > -1
				);
			})
		);
	}, [search]);

	useEffect(() => {
		const result = data.find((langItem) => {
			return (
				langItem?.lang_iso_code?.toUpperCase().substring(0, 2) ==
				language?.toUpperCase().substring(0, 2)
			);
		});
		if (result) {
			dayjs.locale(language);
			i18n.changeLanguage(language);
			dispatch(settingsActions.setLanguage(language));
		} else {
			dayjs.locale('en');
			i18n.changeLanguage('en');
			dispatch(settingsActions.setLanguage('en'));
		}
	}, [language]);

	useEffect(() => {
		getRouteTranslations();
	}, []);

	if (!data) {
		return null;
	}

	return (
		<div style={{ marginLeft: 'auto' }}>
			<ButtonSprache onClick={handleShow}>
				<div>
					{typeof language == 'string'
						? language?.toUpperCase().substring(0, 2)
						: 'EN'}
				</div>
				<div
					style={{
						marginLeft: '37px',
						backgroundColor: 'var(--bs-body-color)',
						color: 'white',
						height: '100%',
						top: '0',
						padding: '3px 7px',
						borderRadius: '0 1px 1px 0',
					}}
				>
					<AngleDown></AngleDown>
				</div>
			</ButtonSprache>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{t('impfschaeden.erfassen.sprachauswahl')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='d-flex align-items-center'>
						<Switch
							label={t('common.maschinelle_uebersetzung')}
							checked={machineTranslation}
							onChange={(e) => {
								dispatch(
									settingsActions.setMachineTranslation(e.target.checked)
								);
							}}
						/>
						<div
							className='cursor-pointer mt-n2 ms-1'
							onClick={() => setShowInfo(!showInfo)}
						>
							<InfoOutlined />
						</div>
					</div>
					{showInfo && (
						<Alert
							icon={false}
							severity='info'
							variant='outlined'
							className='mb-1'
							onClose={() => setShowInfo(false)}
						>
							{t('common.maschinelle_uebersetzung_info_text')}
							{/* Wenn Sie diese Option auf "AN" stellen, werden alle Texte die
							nicht in ihrer gewählten Sprache verfügbar sind maschinell
							übersetzt. Vetopedia übernimmt keine Haftung für mangelhafte
							Übersetzung. */}
						</Alert>
					)}
					<div
						style={{
							width: '100%',
							height: '50px',
							backgroundColor: 'rgb(222, 231, 241)',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<div className='me-1'>{t('common.ausgewaehlte_sprache')}:</div>
						<div
							style={{
								cursor: 'pointer',
								width: '200px',
								padding: '6px',
							}}
						>
							<div
								style={{
									maxWidth: '960px',
									width: 'auto',
								}}
							>
								<div
									style={{
										flexWrap: 'nowrap !important',
										display: 'flex',
									}}
								>
									<div
										style={{
											position: 'relative',
											marginRight: '10px',
										}}
									>
										<div
											style={{
												color: 'black',
												padding: '12px',
												border: '3px var(--bs-body-color) solid',
												width: '55px',
												height: '30px',
												borderRadius: '5px',
												marginTop: '5px',
												paddingLeft: '25px',
												paddingTop: '3px',
											}}
										>
											{language && language.lang_iso_code}
										</div>
									</div>
									<div
										style={{
											lineHeight: '1.4',
										}}
									>
										<div
											style={{
												height: '15px',
												color: 'grey',
											}}
										>
											{language && language.lang_name_de}
										</div>
										<div
											style={{
												color: 'black',
												fontSize: '15px',
												height: '15px',
											}}
										>
											{language && language.lang_name}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='mt-2'>
						<Input
							fullWidth={true}
							type='text'
							label={t('impfschaeden.erfassen.sprache_eingeben')}
							display='block'
							height='25px'
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						></Input>
					</div>
					<div
						style={{
							position: 'relative',
							display: 'flex',
							flexFlow: 'column wrap',
							flexDirection: 'row',
							maxHeight: '590px',
							overflowY: 'scroll',
							cursor: 'pointer',
							pointerEvents: 'auto',
						}}
					>
						{filteredData &&
							filteredData.length > 0 &&
							filteredData.map((item, index) => (
								<div
									style={{
										marginTop: '10px',
										marginLeft: '6px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										flexDirection: 'column-reverse',
										width: '45%',
										height: '40px',
										whiteSpace: 'nowrap',
										cursor: 'pointer',
										pointerEvents: 'auto',
									}}
									key={index + 'lang'}
									onClick={() => {
										changeLanguageOnClick(item.lang_iso_code);
										update({ language: item });
										setShow(false);
										setSearch('');
									}}
								>
									<div
										style={{
											flexWrap: 'nowrap !important',
											display: 'flex',
										}}
									>
										<div
											style={{
												position: 'relative',
												marginRight: '10px',
											}}
										>
											<div
												style={{
													color: 'black',
													padding: '12px',
													border: '3px var(--bs-body-color) solid',
													width: '55px',
													height: '30px',
													borderRadius: '5px',
													marginTop: '5px',
													paddingLeft: '25px',
													paddingTop: '3px',
												}}
											>
												{item.lang_iso_code}
											</div>
										</div>
										<div
											style={{
												lineHeight: '1.4',
											}}
										>
											<div
												style={{
													height: '15px',
													color: 'grey',
												}}
											>
												{item.lang_name_de}
											</div>
											<div
												style={{
													color: 'black',
													fontSize: '15px',
													height: '15px',
												}}
											>
												{item.lang_name}
											</div>
										</div>
									</div>
								</div>
							))}
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
