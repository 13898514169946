import { Button, Col, Container, Row } from 'components/ui';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import mapValues from 'lodash/mapValues';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

import { LexiconAccordion } from 'components/schlagworte/components/LexiconAccordion.js';
import { useState } from 'react';
import { calcColors } from 'components/schlagworte/components/LexiconGridView.js';
import { LexiconButton } from 'VaxForm_08_Impfschaeden/components/LexikonButton.js';
import makePDF from 'lib/gen_lexicon_pdf.js';
import { useUploadLexiconMutation } from 'api/internal.api.ts';

export const prepareTagForLexicon = (tags, t, no_untidy) => {
	const translated = tags
		?.map((item) => {
			let newItem = { ...item };
			let translation = t(
				'master_data.health_damage.' + item.health_damage_i18n_key
			);
			if (
				translation ==
				'master_data.health_damage.' + item.health_damage_i18n_key
			) {
				newItem.name = newItem.health_damage_i18n_key;
			} else {
				newItem.name = translation;
			}
			return newItem;
		})
		.sort((a, b) => a.name.localeCompare(b.name));
	const grouped = groupBy(translated, 'parent_health_damage_id');
	const ordered = mapValues(grouped, (group) => {
		const result = orderBy(group, 'lexical_order');
		return result;
	});
	const others = remove(ordered.null, (tag) => {
		return ordered[tag.glob_health_damage_id] === undefined;
	});
	if (others.length > 0 && !no_untidy) {
		ordered.others = others;
		if (!ordered[0]) {
			ordered[0] = [];
		}
		ordered[0].push({
			created_at: '2023-06-20T22:05:52.000000Z',
			description: '',
			glob_health_damage_id: 'others',
			health_damage_i18n_key: 'einsortieren',
			name: 'Einsortieren',
			lang_iso_code: 'translation',
			lexical_order: 'z',
			parent_health_damage_id: 0,
			updated_at: '2023-06-20T22:05:52.000000Z',
			verified: 1,
		});
	}
	return ordered;
};

const TagLexicon = (props) => {
	const [expandAll, setExpandAll] = useState(false);
	const [edit, setEdit] = useState(props.noEdit ? false : true);
	const [editPlus, setEditPlus] = useState(props.noEdit ? false : true);
	const [t] = useTranslation();
	const [dragging, setDragging] = useState(false);
	const [draggedItem, setDraggedItem] = useState({});
	const [filterVerified, setFilterVerified] = useState(true);
	const [selected, setSelected] = useState([]);
	const [showId, setShowId] = useState(false);
	let timeoutReference;

	const [uploadLexicon] = useUploadLexiconMutation();

	const updateDragging = (value) => {
		clearTimeout(timeoutReference);
		setDragging(value);
		// if (value === true) {
		// 	timeoutReference = setTimeout(() => {
		// 		setDragging(false);
		// 	}, 10000);
		// }
	};
	let tags = cloneDeep(props.tags);
	if (filterVerified) {
		tags = tags.filter((item) => item.verified == true);
	}

	const onSelect = (newValue) => {
		const newSelected = cloneDeep(selected);
		if (selected.indexOf(newValue.glob_health_damage_id) > -1) {
			remove(newSelected, newValue.glob_health_damage_id);
		} else {
			newSelected.push(newValue.glob_health_damage_id);
		}
		setSelected(newSelected);
	};

	const ordered = prepareTagForLexicon(tags, t, props.noEdit);

	// console.log('tagLexicon', props);

	return (
		<Container>
			<Row className='d-print-none'>
				<Col>
					{!props.noEdit && (
						<div className='mb-1 me-1 d-inline-block'>
							<LexiconButton selected={selected} onSelect={onSelect} />
						</div>
					)}

					<Button
						className='mb-1 me-1'
						onClick={() => setExpandAll(!expandAll)}
					>
						{expandAll
							? t('common.alle_zuklappen')
							: t('common.alle_aufklappen')}
					</Button>
					{!props.noEdit && (
						<Button className='mb-1 me-1' onClick={() => setEdit(!edit)}>
							Verschieben umschalten
						</Button>
					)}
					{!props.noEdit && (
						<Button
							className='mb-1 me-1'
							onClick={() => setEditPlus(!editPlus)}
						>
							Bearbeiten umschalten
						</Button>
					)}
					{!props.noEdit && (
						<Button
							className='mb-1 me-1'
							onClick={() => {
								const datauri = makePDF(ordered);
								var formData = new FormData();
								formData.append('file', datauri);
								uploadLexicon(formData);
							}}
						>
							{t('common.pdf_generieren')}
						</Button>
					)}
					{props.enablePrint && (
						<Button
							className='mb-1 me-1'
							onClick={() => {
								let origExpand = expandAll;
								let origEdit = edit;
								let origEditPlus = editPlus;
								setExpandAll(true);
								setEdit(false);
								setTimeout(() => {
									print();
								}, 2000);
								if (!origExpand) {
									setTimeout(() => {
										setExpandAll(false);
									}, 2000);
								}
								if (origEdit) {
									setTimeout(() => {
										setEdit(true);
									}, 2500);
								}
								if (origEditPlus) {
									setTimeout(() => {
										setEditPlus(true);
									}, 2500);
								}
							}}
						>
							{t('common.drucken')}
						</Button>
					)}
					{!props.noEdit && (
						<Button
							className='mb-1 me-1'
							onClick={() => setFilterVerified(!filterVerified)}
						>
							{filterVerified
								? 'unverifizierte anzeigen'
								: 'unverifizierte nicht anzeigen'}
						</Button>
					)}
					{!props.noEdit && (
						<Button className='mb-1 me-1' onClick={() => setShowId(!showId)}>
							ID anzeigen
						</Button>
					)}
				</Col>
			</Row>
			<Row>
				<Col>
					<LexiconAccordion
						level={1}
						data={ordered}
						start={'0'}
						expandAll={expandAll}
						setExpandAll={setExpandAll}
						edit={edit}
						editPlus={editPlus}
						dragging={dragging}
						setDragging={updateDragging}
						draggedItem={draggedItem}
						setDraggedItem={setDraggedItem}
						getHealthDamages={props.getTags}
						showId={showId}
						levelBgColorMap={calcColors(
							ordered[0].find((item) => item.glob_health_damage_id == 0)?.color
						)}
						levelColorMap={calcColors(
							ordered[0].find((item) => item.glob_health_damage_id == 0)?.color
						)}
					/>
				</Col>
			</Row>
		</Container>
	);
};

TagLexicon.propTypes = {
	noEdit: PropTypes.bool,
	enablePrint: PropTypes.bool,
	tags: PropTypes.array,
	getTags: PropTypes.func,
};

export { TagLexicon };
