import { SearchableFilter } from 'components/impfschaeden/uebersicht/components/SearchableFilter.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';

const ManufacturerFilter = ({ filter, updateFilter, onBack, handleClose }) => {
	const [t] = useTranslation();
	const [options, setOptions] = useState([]);
	const vaxManufacturers = useSelector(
		(state) => state.masterData.vaxManufacturers
	);

	const manufacturerCounts = useSelector(
		(state) => state.masterData.vaxManufacturerCounts
	);

	useEffect(() => {
		if (vaxManufacturers?.length > 0 && manufacturerCounts?.length > 0) {
			setOptions(
				orderBy(
					vaxManufacturers
						.map((manufacturer) => {
							return {
								key: manufacturer.manufacturer_i18n_key.toLowerCase(),
								name: manufacturer.manufacturer_i18n_key,
								id: manufacturer.vax_manufacturer_id,
								count: manufacturerCounts.find((item) => {
									return (
										item.vax_manufacturer_id == manufacturer.vax_manufacturer_id
									);
								})?.entries_count,
							};
						})
						.filter((item) => {
							return !!item.count && item.id != 1;
						}),
					['count'],
					['desc']
				)
			);
		}
	}, [manufacturerCounts]);

	const defaultOption = null;

	// console.log('manufacturer filter', options);

	return (
		<SearchableFilter
			options={options}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='manufacturer'
			title={t('common.hersteller')}
			placeholder={t('common.hersteller_suchen')}
			handleClose={handleClose}
			skipTranslation={true}
			display={(option, rawOption) => {
				return '' + rawOption.count + ' ' + t('common.faelle');
			}}
		/>
	);
};

ManufacturerFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { ManufacturerFilter };
