import { useVaccinationCountsLazyQuery } from 'api/vax.api.js';
import { SearchableFilter } from 'components/impfschaeden/uebersicht/components/SearchableFilter.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';

const VaccinationFilter = ({ filter, updateFilter, onBack, handleClose }) => {
	const [t] = useTranslation();
	const [options, setOptions] = useState([]);
	const vaxTypes = useSelector((state) => state.masterData.vaxTypes);
	const vaccinationCounts = useSelector(
		(state) => state.masterData.vaxTypeCounts
	);

	useEffect(() => {
		if (vaxTypes?.length > 0 && vaccinationCounts?.length > 0) {
			setOptions(
				orderBy(
					vaxTypes
						.map((vaccination) => {
							return {
								key: vaccination.vax_type_i18n_key.toLowerCase(),
								name: vaccination.vax_type_i18n_key,
								id: vaccination.vax_type_id,
								count: vaccinationCounts.find((item) => {
									return item.vax_type_id == vaccination.vax_type_id;
								})?.entries_count,
							};
						})
						.filter((item) => {
							return !!item.count && item.id != -1;
						}),
					['count'],
					['desc']
				)
			);
		}
	}, [vaccinationCounts]);

	const defaultOption = null;
	return (
		<SearchableFilter
			options={options}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='vaccination'
			title={t('common.impfung')}
			placeholder={t('common.impfung_suchen')}
			handleClose={handleClose}
			skipTranslation={true}
			display={(option, rawOption) => {
				return '' + rawOption.count + ' ' + t('common.faelle');
			}}
		/>
	);
};

VaccinationFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { VaccinationFilter };
