'use client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
	LexiconItem,
	calcTextColor,
} from 'components/schlagworte/components/LexiconItem.js';
import { useTranslation } from 'react-i18next';
import {
	MUIAccordion,
	AccordionDetails,
	AccordionSummary,
	ExpandMore,
} from 'components/ui';
import styled from 'styled-components';
import { calcColors } from 'components/schlagworte/components/LexiconGridView.js';

const StyledAccordionSummary = styled(AccordionSummary)`
	@media print {
		background-color: transparent !important;
		/* border-left: 2px solid; */
	}
	& > .MuiAccordionSummary-expandIconWrapper > .MuiSvgIcon-root {
		@media print {
			display: none;
		}
	}
`;

const LexiconAccordion = (props) => {
	const [t] = useTranslation();
	const [expanded, setExpanded] = useState(
		props.data[props.start].length == 1
			? 'panel' + props.level + props.data[props.start][0].lexical_order
			: false
	);
	const levelColorMap = props.levelColorMap || [
		null,
		'#008000',
		'#ee82ee',
		'#ffff00',
		'#007bff',
	];
	const levelBgColorMap = props.levelBgColorMap || [
		null,
		'#D9ECD9',
		'#FCECFC',
		'#FFFFD9',
		'#D9EBFF',
	];

	const handleChange = (panel) => (event, isExpanded) => {
		if (props.expandAll && isExpanded == false) {
			return;
		}
		setExpanded(isExpanded ? panel : false);
		// props.setExpandAll(false);
	};

	useEffect(() => {
		if (props.expandAll === true) {
			setExpanded(true);
		}
	}, [props.expandAll]);

	const tags = props.data[props.start];

	if (!tags) {
		return null;
	}
	let dragOverTimeoutHandler = null;

	return tags.map((tag) => {
		if (props.data[tag.glob_health_damage_id]?.length > 0) {
			//|| props.level == 1
			return (
				<div
					style={{ color: levelColorMap[props.level] }}
					key={tag.glob_health_damage_id}
				>
					<MUIAccordion
						disableGutters={true}
						TransitionProps={{ unmountOnExit: true }}
						expanded={
							expanded === 'panel' + props.level + tag.lexical_order ||
							props.expandAll === true
						}
						onDragLeave={(e) => {
							e.preventDefault();
							clearInterval(dragOverTimeoutHandler);
							dragOverTimeoutHandler = null;
						}}
						onDragOver={(e) => {
							e.preventDefault();
							if (dragOverTimeoutHandler) {
								return;
							}
							dragOverTimeoutHandler = setTimeout(() => {
								handleChange('panel' + props.level + tag.lexical_order)(
									e,
									true
								);
							}, 1500);
						}}
						onChange={handleChange('panel' + props.level + tag.lexical_order)}
						style={
							props.level == 1
								? {
										// borderLeft: '5px solid ' + levelColorMap[props.level],
										borderBottomLeftRadius: 0,
										borderBottomRightRadius: 0,
										borderTopLeftRadius: 0,
										borderTopRightRadius: 0,
										boxShadow: 'none',
								  }
								: {
										// borderLeft: '5px solid ' + levelColorMap[props.level],
										boxShadow: 'none',
										borderBottomLeftRadius: 0,
										borderBottomRightRadius: 0,
										borderTopLeftRadius: 0,
										borderTopRightRadius: 0,
								  }
						}
					>
						<StyledAccordionSummary
							sx={{
								paddingLeft: '15px',
								margin: 0,
								backgroundColor: tag.color
									? tag.color
									: levelBgColorMap[props.level],
								'& .MuiAccordionSummary-content': {
									margin: 0,
								},
								borderColor: tag.color
									? tag.color
									: levelBgColorMap[props.level],
								'& .MuiAccordionSummary-expandIconWrapper': {
									color: calcTextColor(props.levelBgColorMap[props.level]),
								},
							}}
							// style={{}} //30 - props.level * 5 + 'px' }}
							expandIcon={<ExpandMore />}
							aria-controls={
								'panel' + props.level + tag.lexical_order + 'bh-content'
							}
							id={'panel' + props.level + tag.lexical_order + 'bh-header'}
						>
							<LexiconItem
								key={tag.glob_health_damage_id}
								tag={tag}
								level={props.level}
								levelBgColorMap={
									props.level == 1
										? calcColors(
												props.data[props.start].find(
													(item) =>
														item.glob_health_damage_id ==
														tag.glob_health_damage_id
												)?.color
										  )
										: props.levelBgColorMap
								}
								levelColorMap={
									props.level == 1
										? calcColors(
												props.data[props.start].find(
													(item) =>
														item.glob_health_damage_id ==
														tag.glob_health_damage_id
												)?.color
										  )
										: props.levelColorMap
								}
								lastItem={false}
								dragging={props.dragging}
								setDragging={props.setDragging}
								draggedItem={props.draggedItem}
								setDraggedItem={props.setDraggedItem}
								getHealthDamages={props.getHealthDamages}
								edit={props.edit}
								editPlus={props.editPlus}
								data={props.data}
								selectionMode={props.selectionMode}
								selected={props.selected}
								onSelect={props.onSelect}
								showId={props.showId}
							/>
						</StyledAccordionSummary>
						<AccordionDetails
							style={
								props.level == 1
									? {
											paddingTop: '0px',
											paddingLeft: '0px',
											paddingBottom: '0px',
											paddingRight: '0px',
									  }
									: {
											paddingTop: '0px',
											paddingLeft: '0px',
											paddingBottom: '0px',
											paddingRight: '0px',
											borderRight: 0,
											boxShadow: '0px 0px 0px #ffffff',
									  }
							}
						>
							{props.data[tag.glob_health_damage_id]?.length > 0 && (
								<LexiconAccordion
									{...props}
									level={props.level + 1}
									start={tag.glob_health_damage_id}
									levelBgColorMap={
										tag.color ? calcColors(tag.color) : props.levelBgColorMap
									}
									levelColorMap={
										tag.color ? calcColors(tag.color) : props.levelColorMap
									}
								/>
							)}
						</AccordionDetails>
					</MUIAccordion>
				</div>
			);
		} else {
			return (
				<LexiconItem
					key={tag.glob_health_damage_id}
					data={props.data}
					tag={tag}
					level={props.level}
					levelBgColorMap={
						props.level == 1
							? calcColors(
									props.data[props.start].find(
										(item) =>
											item.glob_health_damage_id == tag.glob_health_damage_id
									)?.color
							  )
							: props.levelBgColorMap
					}
					levelColorMap={
						props.level == 1
							? calcColors(
									props.data[props.start].find(
										(item) =>
											item.glob_health_damage_id == tag.glob_health_damage_id
									)?.color
							  )
							: props.levelColorMap
					}
					lastItem={true}
					dragging={props.dragging}
					setDragging={props.setDragging}
					draggedItem={props.draggedItem}
					setDraggedItem={props.setDraggedItem}
					getHealthDamages={props.getHealthDamages}
					edit={props.edit}
					editPlus={props.editPlus}
					selectionMode={props.selectionMode}
					selected={props.selected}
					onSelect={props.onSelect}
					showId={props.showId}
				/>
			);
		}
	});
};

LexiconAccordion.propTypes = {
	level: PropTypes.number,
	data: PropTypes.object,
	start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	expandAll: PropTypes.bool,
	edit: PropTypes.bool,
	editPlus: PropTypes.bool,
	dragging: PropTypes.bool,
	setDragging: PropTypes.func,
	setExpandAll: PropTypes.func,
	draggedItem: PropTypes.object,
	setDraggedItem: PropTypes.func,
	getHealthDamages: PropTypes.func,
	selectionMode: PropTypes.bool,
	selected: PropTypes.array,
	onSelect: PropTypes.func,
	levelColorMap: PropTypes.array,
	levelBgColorMap: PropTypes.array,
	showId: PropTypes.bool,
};

export { LexiconAccordion };
