import { createSlice } from '@reduxjs/toolkit';
import { initialVaccinationData } from 'VaxForm_09_Impfungen/VaxForm_09.js';
import getFileIndex from './helpers/getFileIndex';
import { base62 } from 'mudder';
import useUpdateCheck from 'hooks/useUpdateCheck.js';

export type Vaccination = {};

export type HealthDamage = {};

export type GlobFile = {};

export type User = {
	user_id: number;
	email: string;
};

export type VaxEntry = {
	visible_first_name: number;
	visible_last_name: number;
	visible_city: number;
	visible_avatar: number;
	user: User;
	vax_entry_vaccinations: Vaccination[];
	vax_pre_health_damage_tags: HealthDamage[];
	vax_health_damage_tags: HealthDamage[];
	avatar_glob_file_id: number;
	vax_entry_files: GlobFile[];
	age_unit: string;
	glob_age_groups_id: string;
	internet_link: string;
	case_id: string;
	glob_referrer_id: string;
	own_entry: '1' | '0';
	originally_posted_at: string;
	created_at: string;
};

const presetData = {
	visible_first_name: 1,
	visible_last_name: 1,
	visible_city: 1,
	visible_avatar: 1,
	user: {},
	vax_entry_vaccinations: [initialVaccinationData],
	vax_pre_health_damage_tags: [],
	vax_health_damage_tags: [],
	avatar_glob_file_id: 1,
	vax_entry_files: [],
	age_unit: 'jahr',
	glob_age_groups_id: '1',
};

const unknownPreset = {
	vax_relationship_id: '1',
	vax_entry_vaccinations: [initialVaccinationData],
	glob_referrer_id: '1',
	healthy_before_vax: 'unknown',
	vax_damage_occurred: '1',
	glob_health_result_id: '1',
	hospitalized: 'unknown',
};

// const devData = {
// 	// TODO remove before publish
// 	user: {
// 		first_name: 'Lea',
// 		last_name: 'Musterfrau',
// 		email: 'test@test.de',
// 		password: 'test',
// 	},
// 	anotherPerson: true,
// 	first_name: 'Max',
// 	last_name: 'Mustermann',
// 	country_iso_code: 'de',
// 	city: '',
// 	// sex: 'm',
// 	vax_relationship_id: 2,
// 	glob_referrer_id: 1,
// 	internet_link: 'example.com',
// 	case_id: '1234',
// 	// healthy_before_vax: 'no',
// 	vax_pre_health_damage_tags: ['Sehr Krank'],
// 	vax_entry_vaccinations: [
// 		{
// 			vax_type_id: '38',
// 			glob_age_groups_id: '3',
// 			batch_number: 'abc12313',
// 			vax_manufacturer_id: '13',
// 			admin_month: '04',
// 			admin_year: '1925',
// 			admin_day: '0',
// 		},
// 	],
// 	description: 'Mir gings schlecht',
// 	// hospitalized: 'yes',
// 	// glob_health_result_id: '15',
// 	vax_health_damage_tags: ['Juckreiz'],
// 	// vax_damage_occurred: '9',
// 	avatar_glob_file_id: '1',
// };

const getInitialState = () => {
	const presetData = getPresetData();
	const { hasUpdate } = useUpdateCheck();
	if (typeof window !== 'undefined' && !hasUpdate()) {
		const localStorageData = JSON.parse(
			localStorage.getItem('vaxFormData') ?? ''
		);
		if (localStorageData) {
			return { ...presetData, ...localStorageData };
		}
	}

	return presetData;
};

const getPresetData = () => {
	return {
		...presetData,
		// ...devData, // TODO remove before publish
		...unknownPreset, // TODO remove before publish
	};
};

const getLexicalOrder = (index, array, attributeName = 'lexical_order') => {
	// console.log('getLexicalOrder', index, array);
	if (!array || !index || !array.length) {
		return base62.mudder('1', 'z')[0];
	}
	if (index == -1) {
		return base62.mudder(array[array.length - 1][attributeName], 'z')[0];
	} else if (index == 0) {
		if (array.length > 1) {
			return base62.mudder('1', array[2][attributeName])[0];
		}
	} else if (index >= array.length - 1) {
		if (array.length > 1) {
			return base62.mudder(array[array.length - 1][attributeName], 'z')[0];
		} else {
			return base62.mudder(array[0][attributeName], 'z')[0];
		}
	} else {
		return base62.mudder(
			array[index - 1][attributeName],
			array[index + 1][attributeName]
		)[0];
	}
	return base62.mudder('1', 'z')[0];
};

const vaxFormDataSlice = createSlice({
	name: 'vaxFormData',
	initialState: getInitialState,
	reducers: {
		resetForm(state) {
			const user = { ...state.user };
			return { ...getPresetData(), user };
		},
		update(state, action) {
			return { ...state, ...action.payload };
		},
		updateUser(state, action) {
			state.user = {
				...state.user,
				...action.payload,
			};
		},
		updateVaccination(state, action) {
			state.vax_entry_vaccinations = action.payload;
		},
		updateFile(state, action) {
			// console.log('updateFile', state, action);
			const copy = [...state.vax_entry_files];
			copy.push(action.payload);
			state.vax_entry_files = copy;
		},
		changeFileOrder(state, { payload }) {
			const index = getFileIndex(state.vax_entry_files, payload);
			state.vax_entry_files[index].lexical_order = payload.lexical_order;
		},
		upload(state, { payload }) {
			// console.log('upload', state, payload);
			const index = getFileIndex(state.vax_entry_files, payload);
			if (index > -1) {
				state.vax_entry_files[index].filename = payload.filename;
				state.vax_entry_files[index].src = payload.src;
				state.vax_entry_files[index].lexical_order = getLexicalOrder(index, [
					...state.vax_entry_files,
				]);
			} else {
				state.vax_entry_files.push({
					filename: payload.filename,
					src: payload.src,
					lexical_order: getLexicalOrder(
						state.vax_entry_files.length,
						JSON.parse(JSON.stringify(state.vax_entry_files))
					),
				});
			}
		},
		uploadStatus(state, { payload }) {
			// console.log('update Status');
			const index = getFileIndex(state.vax_entry_files, payload);
			const progress = Math.round((100 * payload.loaded) / payload.total);
			state.vax_entry_files[index].progress = progress;
		},
		uploadFinished(state, { payload }) {
			// console.log('upload finished');
			const index = getFileIndex(state.vax_entry_files, payload);
			state.vax_entry_files[index] = {
				...payload,
				lexical_order: state.vax_entry_files[index].lexical_order,
				progress: 100,
				src: `${process.env.API_URL}v1/glob/files/${payload.glob_file_id}/${payload.filename}`,
			};
		},
		uploadError(state, { payload }) {
			const index = getFileIndex(state.vax_entry_files, payload);
			state.vax_entry_files[index] = {
				...payload,
				lexical_order: state.vax_entry_files[index].lexical_order,
				progress: 100,
				error: payload.error,
				src: `${process.env.API_URL}v1/glob/files/${payload.glob_file_id}/${payload.filename}`,
			};
		},
		download() {},
		removeFile(state, { payload }) {
			const index = getFileIndex(state.vax_entry_files, payload);
			state.vax_entry_files.splice(index, 1);
		},
	},
});

export const formActions = vaxFormDataSlice.actions;

export default vaxFormDataSlice.reducer;

// export const updateVaxFormData = (payload) => async (dispatch) => {
// 	dispatch({
// 		type: 'VAX_FORM_UPDATE_DATA',
// 		payload: payload,
// 	});
// };

// export const vaxFormReducer = (state = initialState, action) => {
// 	switch (action.type) {
// 		case 'VAX_FORM_SAVE_DATA':
// 			return {
// 				...state,
// 				vaxFormData: initialState.vaxFormData,
// 			};

// 		default:
// 			return state;
// 	}
// };
